import Head from "next/head";
import { useAuth } from "@/contexts/auth";

const Home = () => {
  const { isLoading } = useAuth();
  return (
    <>
      {!isLoading && (
        <Head>
          <script
            // If no token is found, redirect inmediately
            dangerouslySetInnerHTML={{
              __html: `location.replace("/dashboard/home")`,
            }}
          />
        </Head>
      )}
    </>
  );
};

Home.requiresProfile = true;
Home.requiresAuth = true;
Home.redirectUnauthenticated = "/login";

export default Home;
